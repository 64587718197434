import type { RouteLocationRaw } from 'vue-router'

export type Yesno = 'N' | 'Y'
export const kundenSearchValueKey = 'kunden-searchValue'
export const kundenTableSchemeKey = 'kunden-tableScheme'
export const kundenShowInactiveKey = 'kunden-showInactive'
export const kundenPageKey = 'kunden-page'

export function clearCustomerPageSessionCache() {
    sessionStorage.removeItem(kundenSearchValueKey)
    sessionStorage.removeItem(kundenPageKey)
    sessionStorage.removeItem(kundenTableSchemeKey)
    sessionStorage.removeItem(kundenShowInactiveKey)
}

export interface Subpage {
    label: string,
    icon?: string | string[],
    route: RouteLocationRaw
}

export interface Checkbox {
    id: number
    label: string
    isChecked: boolean
    hint?: string
}

export interface UpdateCheckbox {
    id: number
    label: string
    wasChecked: boolean
    isChecked: boolean
}

export interface UpdateCheckbox {
    id: number
    label: string
    wasChecked: boolean
    isChecked: boolean
}
