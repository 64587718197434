<script lang="ts" setup>
import { api } from "@/api/api.ts"
import { useGlobalStore } from "@/store/globals.ts"
import urlToColor from '@/utils/urlToColor.ts'
import { computed } from 'vue'
import { useRoute } from "vue-router"

const route = useRoute()
const globals = useGlobalStore()
const version = import.meta.env.VITE_APP_VERSION

const urlColor = computed(() => urlToColor(api.baseURL.value))

</script>

<template>
    <div
        class="flex w-full flex-col justify-end gap-1 bg-transparent py-1 text-right text-sm desktop:flex-row desktop:gap-4 desktop:text-left"
    >
        <RouterLink
            v-if="route.fullPath !== '/config' && globals.showAPIConfig"
            :to="{name: 'config'}"
            class="grow"
        >
            <IconFA
                class="ml-1 aspect-square cursor-pointer text-lg text-gray-400 transition focus:outline-none desktop:mr-5"
                icon="gear"
            />
        </RouterLink>
        <template
            v-if="globals.showAPIConfig"
        >
            <p
                :style="{color: urlColor}"
                class="text-gray-400"
            >
                {{ api.baseURL.value }}
            </p>
            <IconFA
                v-if="globals.isDesktop"
                class="w-2 self-center text-gray-400"
                icon="minus"
            />
        </template>
        <p class="text-gray-400">
            Version {{ version }}
        </p>
        <IconFA
            v-if="globals.isDesktop"
            class="w-2 self-center text-gray-400"
            icon="minus"
        />
        <a
            class="text-gray-400"
            href="https://www.curasoft.de/datenschutzerklaerung/"
        >
            Datenschutz
        </a>
        <IconFA
            v-if="globals.isDesktop"
            class="w-2 self-center text-gray-400"
            icon="minus"
        />
        <a
            class="text-gray-400"
            href="https://www.curasoft.de/impressum/"
        >
            Impressum
        </a>
    </div>
</template>

<style></style>
