<script lang="ts" setup>
import { api } from "@/api/api"
import AppLoader from '@/components/AppLoader.vue'
import { useFaviconColor } from "@/helper/faviconColor"
import { providePageBreadcrumb, providePageHeading } from "@/helper/UpdatePageInfo.ts"
import Footer from "@/layouts/Footer.vue"
import MainNav from "@/layouts/MainNav/MainNav.vue"
import { useAuthStore } from "@/store/AuthStore.ts"
import { useGlobalStore } from "@/store/globals.ts"
import urlToColor from '@/utils/urlToColor.ts'
import { Notifications } from '@kyvg/vue3-notification'
import { useRegisterSW } from 'virtual:pwa-register/vue'
import { onMounted, ref, watch } from "vue"
import { useRouter } from 'vue-router'

const {setColoredIcon, setDefaultIcon} = useFaviconColor()
watch(() => api.baseURL.value, (url) => {
    const color = urlToColor(url)
    if (color) {
        setColoredIcon(color)
    } else {
        setDefaultIcon()
    }
}, {immediate: true})

const authStore = useAuthStore()
const globals = useGlobalStore()

const {heading} = providePageHeading("")

const {breadcrumbs} = providePageBreadcrumb()
const isSpacerActive = ref(true)

onMounted(() => {
    void import('chart.js').then(({Chart}) => {
        // Set Chart Defaults
        //@ts-ignore according to https://www.chartjs.org/docs/latest/api/interfaces/LegendOptions.html there is a display option
        if (Chart.defaults.plugins.legend == undefined) Chart.defaults.plugins.legend = {display: false}
        //@ts-ignore
        if (Chart.defaults.plugins.tooltip == undefined) Chart.defaults.plugins.tooltip = {enabled: false}
        //@ts-ignore
        if (Chart.defaults.plugins.datalabels == undefined) Chart.defaults.plugins.datalabels = {display: false}
        //@ts-ignore
        if (Chart.defaults.plugins.title == undefined) Chart.defaults.plugins.title = {display: false}
    })
})

let registration: ServiceWorkerRegistration | null = null
const showAppLoader = ref(false)
const {
    needRefresh,
    updateServiceWorker,
} = useRegisterSW({
    onRegisteredSW(swScriptUrl, _registration) {
        registration = _registration ?? null
        if (registration == null) return
        setInterval(() => {
            registration?.update()
        }, 10 * 60 * 1000) // 10 minutes
        registration.update()
            .then(() => refreshSW())
    },
})

function refreshSW() {
    if (needRefresh.value) {
        showAppLoader.value = true
        setTimeout(() => {
            updateServiceWorker()
        }, import.meta.env.VITE_MODE !== 'production' ? 500 : 3000 + Math.random() * 6000) // artificial delay for better feel
    }
}

const router = useRouter()
router.beforeEach(() => {
    refreshSW()
})
</script>

<template>
    <Notifications class="mt-16 text-xl"/>
    <AppLoader
        v-if="showAppLoader"
    />
    <div
        v-else
        class="gradient-background dark:dark-gradient-background flex min-h-screen flex-col"
    >
        <MainNav
            :heading="heading"
        />
        <div
            v-if="globals.isDesktop && authStore.isLoggedIn"
            class="mt-4 grid min-h-8 w-full grid-cols-2 px-11"
        >
            <h2
                class="col-start-1 font-display text-2xl capitalize text-white"
                v-html="heading"
            ></h2>
            <div class="col-start-2 justify-self-end text-2xl text-white">
                <TransitionGroup
                    enter-active-class="duration-500"
                    enter-from-class="opacity-0"
                    enter-to-class="opacity-100"
                >
                    <span
                        v-for="(crumb, index) in breadcrumbs"
                        :key="`${crumb.label}-${index}`"
                    >
                        <IconFA
                            v-if="index !== 0"
                            class="aspect-square h-[0.8em] px-1 text-sm"
                            icon="chevron-right"
                        />
                        <RouterLink
                            v-if="crumb.route != undefined"
                            :to="crumb.route"
                            class="text-sm hover:underline"
                        >
                            {{ crumb.label }}
                        </RouterLink>
                        <span
                            v-else
                            :key="`${crumb.label}-${index}`"
                            class="cursor-default text-sm"
                        >
                            {{ crumb.label }}
                        </span>
                    </span>
                </TransitionGroup>
            </div>
        </div>
        <div
            v-if="isSpacerActive"
            class="grow"
        ></div>
        <RouterView v-slot="{ Component }">
            <Transition
                mode="out-in"
                name="zoom"
                @enter="isSpacerActive = false"
                @after-leave="isSpacerActive = true"
            >
                <Component :is="Component"/>
            </Transition>
        </RouterView>
        <Footer
            v-if="$route.meta.withoutAuth || globals.isDesktop"
            class="mt-4 flex px-2 desktop:px-11"
        />
    </div>
</template>

<style>
html {
    scroll-behavior: smooth;
}
</style>
