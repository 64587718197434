import { api } from '@/api/api'
import type AuthCheckModel from '@/models/AuthCheck'

export function check(): Promise<AuthCheckModel> {
    return api.fetchParsed('/webapi/auth/check')
}

export function login(username: string,
                      password: string,
): Promise<string> {
    return api.fetchParsed('/auth/v1/user', {
        requestOptions: {
            headers: {'Authorization': 'Basic ' + btoa(`${username}:${password}`)},
        },
    })
}


