import type { OnError } from '@/api/types'
import { APIParseResponseError as APIError } from '@/api/types'
import { log, LoggableError } from '@/log/log.ts'
import { notify } from '@kyvg/vue3-notification'

export async function parseResponse(response: Response) {
    const _response = response.clone()
    if (_response.ok && _response.headers.get('Content-Type')?.includes('/json')) {
        return await _response.json()
    } else if (_response.ok && _response.status === 200 && (
        _response.headers.get('Content-Type')?.startsWith('image/') ||
        _response.headers.get('Content-Type')?.startsWith('application/')
    )) {
        return await _response.blob()
    } else if (_response.ok && _response.status === 204) {
        return undefined // 204 do nothing
    } else if (_response.ok && _response.status === 200 && _response.headers.get('Content-Type')?.startsWith('text/')) {
        return await _response.text()
    } else if (_response.ok) {
        if (_response.headers.has('Content-Type')) {
            const contentType = _response.headers.get('Content-Type')
            throw new Error(`unexpected content type: ${contentType}`)
        } else {
            throw new Error('No Content-Type header.')
        }
    } else {
        throw new Error('response status code >= 400')
    }
}

/** This function is a helper for the api requests and not intended to handle a error from the api. */
export function catchAPIError(error: any, onError: OnError | undefined = undefined) {
    if (error instanceof APIError && onError != undefined) {
        onError(error)
    } else {
        throw error
    }
}

export function errorToString(error: unknown): string {
    let message = ''
    if (error instanceof LoggableError) {
        message = error.name
        if (error.context.length > 0) {
            message += `\nContext:\n`
        }

        for (const ctx of error.context) {
            message += '\n' + JSON.stringify(ctx, null, 2)
        }

        if (error.stack != undefined) {
            message += `\nStacktrace:\n${error.stack}`
        }
    } else if (error instanceof APIError) {
        message = error.name
        message += `\n${error.statusCode}`
        message += `\n${error.message}`

        if (error.stack != undefined) {
            message += `\nStacktrace:\n${error.stack}`
        }
    } else if (error instanceof Error) {
        message = error.message
        message += `\n${error.message}`

        if (error.stack != undefined) {
            message += `\nStacktrace:\n${error.stack}`
        }
    } else if (typeof error === 'string') {
        message = error
    } else {
        message = String(error)
    }

    //@ts-expect-error -- cause is implemented in major browsers
    if (error?.cause != undefined) {
        //@ts-expect-error -- cause is implemented in major browsers
        message += `\nCaused by: \n${errorToString(error.cause)}`
    }

    return message
}

export function notifyError(error: unknown): void {
    log.addError(error)
    notify({
        type: 'error',
        title: `Fehler`,
        text: createMessage(error),
    })
}

export function createMessage(error: unknown): string {
    let message = ''
    if (error instanceof LoggableError) {
        const ctx = error.getContext()
        message = ctx.find(value => value.contextType === 'message')?.message ?? ''
        if (message === '') {
            const requestContext = ctx.find(value => value.contextType === 'request')
            if (requestContext != undefined) {
                message = `${requestContext.requestInit.method} ${requestContext.url}`
                const responseContext = ctx.find(value => value.contextType === 'response')
                if (responseContext != undefined) {
                    message += `\n${responseContext.status} ${responseContext.statusText}`
                } else {
                    message += `\n no response (internet not connected?)`
                }
            }
        }
    }
    if (message === '' && error instanceof Error) {
        message = error.message
    }
    return message
}

export function getResponseStatusCode(error: unknown): number | undefined {
    if (error instanceof APIError) {
        return error.statusCode
    }
    if (error instanceof LoggableError) {
        return error.getContext().find(value => value.contextType === 'response')?.status
    }
}

export function notifyErrorNotAuthRelated(error: unknown): void {
    const statusCode = getResponseStatusCode(error)
    if (statusCode !== 401 && statusCode !== 403) {
        notifyError(error)
    }
}