import { parseResponse } from '@/api/utils.ts'
import { createRequestContextFromRequest, createResponseContextFromResponse, LoggableError } from '@/log/log.ts'
import { useLocalStorage } from '@vueuse/core'
import qs from 'qs'
import { ref, watch } from 'vue'

export interface ApiError {
    request: Request
    error: Error
}

export class Api {
    baseURL = ref('')
    baseRequestOptions: RequestInit = {}
    baseURLParameters: object = {}

    authorize(bearerToken: string): void {
        if (bearerToken.length > 0) {
            this.baseRequestOptions['headers'] = {'Authorization': `Bearer ${bearerToken}`}
        }
        window.localStorage.setItem('token', bearerToken)
    }

    async fetch(url: RequestInfo | URL, options: {
        urlParameters?: object,
        requestOptions?: RequestInit
    } = {}): Promise<Response> {
        const req = this.makeRequest(url, options)
        try {
            return await window.fetch(req.url, req.init)
        } catch (e) {
            const requestContext = createRequestContextFromRequest(req.url, req.init)
            throw new LoggableError([requestContext], e)
        }
    }

    async fetchParsed(url: RequestInfo | URL, options: {
        urlParameters?: object,
        requestOptions?: RequestInit
    } = {}) {

        const request = this.makeRequest(url, options)
        let response: Response | null = null

        try {
            response = await window.fetch(request.url, request.init)
        } catch (e) {
            const requestContext = createRequestContextFromRequest(request.url, request.init)
            throw new LoggableError([requestContext], e)
        }

        if (!response.ok){
            const requestContext = createRequestContextFromRequest(request.url, request.init)
            const responseContext = await createResponseContextFromResponse(response)
            throw new LoggableError([requestContext, responseContext])
        }

        try {
            return await parseResponse(response)
        } catch (e) {
            if ((e as Error)?.name === 'AbortError') return // ignore aborts
            const requestContext = createRequestContextFromRequest(request.url, request.init)
            const responseContext = await createResponseContextFromResponse(response)
            throw new LoggableError([requestContext, responseContext], e)
        }
    }

    unauthorize(): void {
        if (this.baseRequestOptions.headers) {
            delete this.baseRequestOptions.headers['Authorization']
        }
        window.localStorage.removeItem('token')
    }

    private makeRequest(url: RequestInfo | URL, options: {
        urlParameters?: object,
        requestOptions?: RequestInit
    } = {
        urlParameters: {},
    }) {
        let parsedURL = this.baseURL.value + String(url)

        parsedURL += qs.stringify({...this.baseURLParameters, ...options.urlParameters}, {
            addQueryPrefix: true,
            skipNulls: true,
            encodeValuesOnly: true,
        })

        return {
            url: parsedURL,
            init: {...this.baseRequestOptions, ...options.requestOptions} as RequestInit,
        }
    }
}

class Backend extends Api {
    isDebugMode = useLocalStorage('isDebugMode', false)

    constructor() {
        super()
        this.checkRequiredBackendURL()
        const localToken = window?.localStorage.getItem('token')
        if (localToken) this.authorize(localToken)

        this.baseURL.value = this.initBaseURL()

        watch(() => this.isDebugMode.value, (isDebugMode) => {
            if (isDebugMode) {
                this.baseURLParameters['XDEBUG_SESSION'] = 'PHPSTORM'
            } else {
                delete this.baseURLParameters['XDEBUG_SESSION']
            }
        })

        watch(() => this.baseURL.value, (baseURL) => {
            localStorage.setItem('api-url', baseURL)
        }, {immediate: true})
    }

    initBaseURL(): string {
        return localStorage.getItem('api-url')
            ?? import.meta.env.VITE_APP_API_BACKEND
            ?? ''
    }

    private checkRequiredBackendURL(): void {
        if (import.meta.env.VITE_APP_API_BACKEND === undefined) {
            throw new Error(
                `missing VITE_APP_API_BACKEND! create a ".env" file
                in project root with
                'VITE_APP_API_BACKEND = "https://your-api-url-target.whatever"'`,
            )
        }
    }

}

export const api = new Backend()
